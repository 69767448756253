import React from 'react';
import cx from 'classnames';
import { filter } from 'lodash';

import BlockContent from '../components/core/block-content.js';

const Modules = item => {
  switch (item.sys.contentType.sys.id) {
    case 'threeColumnLayout':
      return (
        <div
          key={item.sys.id}
          className={cx('p1', {
            bcb: item.fields.backgroundColor === 'dark',
            bcg: item.fields.backgroundColor === 'gray',
          })}
        >
          <div className="container--xl ma p1">
            {item.fields.title && (
              <div
                className={cx('container--xl ma py1', {
                  ac: item.fields.titleAlignment === 'center',
                })}
              >
                <h4 className="caps">{item.fields.title}</h4>
              </div>
            )}
            <div className="f jcb fw three__wrapper">
              {item.fields.modules &&
                item.fields.modules.map(module => {
                  switch (module.sys.contentType.sys.id) {
                    case 'richContent':
                      return (
                        <div key={module.sys.id} className="text s16 pb15">
                          {module.fields.content &&
                            module.fields.content.content.map(single => {
                              switch (single.nodeType) {
                                case 'paragraph':
                                  return (
                                    <p key={Math.random()} className="s16">
                                      {single.content.map(simple => {
                                        switch (simple.nodeType) {
                                          case 'text':
                                            if (simple.marks.length) {
                                              return (
                                                <div
                                                  key={Math.random()}
                                                  dangerouslySetInnerHTML={{
                                                    __html: simple.value,
                                                  }}
                                                />
                                              );
                                            } else {
                                              return (
                                                <span key={Math.random()}>
                                                  {simple.value}
                                                </span>
                                              );
                                            }
                                          case 'hyperlink':
                                            return (
                                              <a
                                                key={Math.random()}
                                                href={simple.data.uri}
                                              >
                                                {simple.content[0].value}
                                              </a>
                                            );
                                          default:
                                            return <div />;
                                        }
                                      })}
                                    </p>
                                  );
                                default:
                                  return <div />;
                              }
                            })}
                        </div>
                      );
                    case 'contactModule':
                      return (
                        <div key={module.sys.id} className="contact">
                          <h3>{module.fields.title}</h3>
                          <p className="caps mt1 s16">
                            <a
                              className="link-hover"
                              href={`mailto:${module.fields.email}`}
                            >
                              {module.fields.email}
                            </a>
                          </p>
                        </div>
                      );
                    case 'textBlock':
                      return (
                        <div key={module.sys.id}>
                          <h5
                            className={cx('small mb0 pb0 akkura', {
                              cw: item.fields.backgroundColor === 'dark',
                            })}
                          >
                            {module.fields.supportingText}
                          </h5>
                        </div>
                      );
                    default:
                      return <div key={module.sys.id}>test</div>;
                  }
                })}
              <div />
            </div>
          </div>
        </div>
      );
    default:
      return <div key={item.sys.id} />;
  }
};

class Page extends React.Component {
  render() {
    const { content } = this.props.pageContext.data;
    return (
      <div className="template__page">
        {content && content.content.map(item => BlockContent(item))}
      </div>
    );
  }
}

export default Page;
